import React from 'react';

type Props = { color?: string };

const InstagramSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='clamp(10px, 3vw, 19px)'
			height='clamp(10px, 3vw, 19px)'
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={{
				marginTop: '1px',
			}}
		>
			<path
				d='M9.75078 0.226074H4.84241C2.66596 0.226074 0.901855 1.99041 0.901855 4.16728V9.07533C0.901855 11.2514 2.66596 13.0155 4.84241 13.0155H9.75097C11.9272 13.0155 13.6913 11.2514 13.6913 9.07533V4.16728C13.6911 1.99041 11.927 0.226074 9.75078 0.226074ZM4.09873 6.64314C4.09873 6.20073 4.18588 5.76264 4.35521 5.35391C4.52454 4.94518 4.77272 4.57381 5.08559 4.26101C5.39846 3.94821 5.76988 3.70011 6.17865 3.53087C6.58741 3.36164 7.02551 3.27459 7.46792 3.27469C7.91028 3.27467 8.34832 3.36178 8.75701 3.53105C9.16571 3.70032 9.53706 3.94843 9.84987 4.26123C10.1627 4.57402 10.4108 4.94536 10.5801 5.35405C10.7494 5.76274 10.8365 6.20078 10.8365 6.64314C10.8364 7.53653 10.4815 8.3933 9.84976 9.02502C9.21805 9.65674 8.3613 10.0117 7.46792 10.0118C6.57444 10.0118 5.71753 9.65697 5.08569 9.02524C4.45385 8.3935 4.09883 7.53664 4.09873 6.64314ZM10.603 4.07855C10.4905 4.07865 10.3791 4.05656 10.2751 4.01357C10.1711 3.97057 10.0766 3.9075 9.99701 3.82796C9.91741 3.74842 9.85428 3.65397 9.81121 3.55001C9.76814 3.44605 9.74599 3.33462 9.74601 3.22209C9.74596 2.99489 9.83612 2.77696 9.99668 2.61621C10.1572 2.45546 10.3751 2.36504 10.6023 2.36483C10.8295 2.36504 11.0474 2.45544 11.2081 2.61616C11.3688 2.77689 11.4591 2.99481 11.4593 3.22209C11.4591 3.44915 11.3689 3.66687 11.2083 3.82745C11.0478 3.98802 10.8301 4.07834 10.603 4.07855Z'
				fill={color}
			/>
			<path
				d='M9.49434 6.64298C9.49442 6.9092 9.44206 7.17282 9.34026 7.4188C9.23846 7.66478 9.08921 7.8883 8.90102 8.07659C8.71284 8.26489 8.48941 8.41428 8.2435 8.51623C7.99758 8.61818 7.73399 8.67069 7.46778 8.67077C7.20158 8.67082 6.93798 8.61844 6.69202 8.51661C6.44607 8.41478 6.22258 8.2655 6.03432 8.07729C5.84606 7.88908 5.69672 7.66563 5.59482 7.4197C5.49292 7.17377 5.44046 6.91019 5.44043 6.64398C5.44035 6.37775 5.49274 6.1141 5.59461 5.86813C5.69647 5.62215 5.84581 5.39867 6.03409 5.21044C6.22237 5.02221 6.4459 4.87294 6.6919 4.77115C6.9379 4.66935 7.20155 4.61703 7.46778 4.61719C8.00514 4.61714 8.52051 4.83053 8.90055 5.21043C9.28059 5.59033 9.49418 6.10562 9.49434 6.64298Z'
				fill={color}
			/>
		</svg>
	);
};

export default InstagramSvg;
